import React from "react";
import { TermsOfUse } from "@tyson-foods/react-component-library";
import Layout from "../components/Layout/Layout";
import "./legal.scss";
import { Link } from "gatsby";
import './terms-of-use.scss';

const Terms = (props) =>{
  return (
    <Layout 
      metaTitle="Terms of Use"
      metaDescription="Please carefully read the Terms of Use before using our site. From time to time, we may update the features on our site, and the terms listed on this page."
    >
      <div className="termsOfUsePage">
        <TermsOfUse
          website="https://www.aidells.com/"
          className="p-3"
          siteName="Aidells Sausage Company "
          privacyPolicyLink={<Link to="/privacy-policy/">PRIVACY POLICY</Link>}
        />
      </div>
    </Layout>
  );
}

export default Terms;
